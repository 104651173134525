import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { RESTClient } from "../client";
import { GET_PATIENTS } from "../contants";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { routesPath } from "../../routes";

export type useQueryPatientsResponse = {
  id: number;
  patient_id: string;
}[];

export type CaseArgs = {
  date: string;
  rooms?: string[] | null;
};

const makeQueryPatients = async (params: Record<string, any>) => {
  const { data } = await RESTClient.get<useQueryPatientsResponse>(`/patients`, {
    params,
  });

  return data;
};

export const useQueryPatients = () => {
  const navigate = useNavigate();
  const processor = useCallback(() => makeQueryPatients({}), []);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_PATIENTS], processor, {
    onError: (error: AxiosError) => {
      if (error.response?.status === 401) {
        navigate(routesPath.login);
      }
    },
  });

  return { data, loading, refetch };
};
