import React from "react";
import classNames from "clsx";
import { UseFormRegister } from "react-hook-form";

type ScoreSelectBoxProps = {
  type: "0-3" | "0-10";
  invalid?: boolean;
};
export const ScoreSelectBox = React.forwardRef<
  HTMLSelectElement,
  ScoreSelectBoxProps & Partial<ReturnType<UseFormRegister<any>>>
>(({ onChange, onBlur, name, invalid, type = "0-3" }, ref) => {
  const opts = type === "0-3" ? new Array(4).fill(0) : new Array(11).fill(0);

  const options: string[] = opts.map((_, index) => index.toString());
  options.push("U");
  return (
    <select
      className={classNames(
        "w-full border cursor-pointer rounded-sm focus:bg-yellow-50",
        {
          "border-red-500": invalid,
        }
      )}
      name={name}
      data-testid={name}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
    >
      {options.map((val, index) => (
        <option key={val} value={val}>
          {val}
        </option>
      ))}
    </select>
  );
});
