import { FormShape, RamrisInputs } from "../../types";
import { FC } from "react";
import { SynovitisMCPJoints } from "./SynovitisMCPJoints";
import { SynovitisWristJoints } from "./SynovitisWristJoints";
import { FillWithZeroButton } from "../../../FillWithZeroButton/FillWithZeroButton";

type SynovitisProps = {
  formName: keyof Omit<RamrisInputs, "visitId">;
  onFillByZero: (
    formName: keyof Omit<RamrisInputs, "visitId">,
    sections: keyof FormShape
  ) => void;
};

export const Synovitis: FC<SynovitisProps> = ({ formName, onFillByZero }) => {
  return (
    <div className="flex flex-col">
      <div className="">
        <div
          data-testid="category"
          className="bg-white inline-block px-2 rounded-sm"
        >
          Synovitis
        </div>
      </div>
      <div className="my-4">
        <SynovitisMCPJoints formName={formName} />
      </div>
      <div className="">
        <SynovitisWristJoints formName={formName} />
      </div>
      <div className="mt-2 self-end">
        <FillWithZeroButton
          onClick={() => onFillByZero(formName, "synovitis")}
        />
      </div>
    </div>
  );
};
