import { FormShape } from "./types";

export const defaultForm: FormShape = {
  damageScore: "0",
  inflammationScore: "0",
  synovitis: {
    MCPJoints: {
      opt1: "0",
      opt2: "0",
      opt3: "0",
      opt4: "0",
      opt5: "0",
    },
    wristJoints: {
      distalRadioUlnarJoint: "0",
      radioCarpalJoint: "0",
      interCarpalCMCJ: "0",
    },
  },
  erosion: {
    MCPJoints: {
      proximal: {
        opt1: "0",
        opt2: "0",
        opt3: "0",
        opt4: "0",
        opt5: "0",
      },
      distal: {
        opt1: "0",
        opt2: "0",
        opt3: "0",
        opt4: "0",
        opt5: "0",
      },
    },
    wristJoints: {
      op1: "0",
      op2: "0",
      op3: "0",
      op4: "0",
      op5: "0",
      opt1: "0",
      opt2: "0",
      opt3: "0",
      opt4: "0",
      opt5: "0",
      trapezium: "0",
      trapezoid: "0",
      capitate: "0",
      hamate: "0",
      scaphoid: "0",
      lunate: "0",
      triquetrum: "0",
      pisiform: "0",
      distalRadius: "0",
      distalUlna: "0",
    },
  },
  oedema: {
    MCPJoints: {
      proximal: {
        opt1: "0",
        opt2: "0",
        opt3: "0",
        opt4: "0",
        opt5: "0",
      },
      distal: {
        opt1: "0",
        opt2: "0",
        opt3: "0",
        opt4: "0",
        opt5: "0",
      },
    },
    wristJoints: {
      op1: "0",
      op2: "0",
      op3: "0",
      op4: "0",
      op5: "0",
      opt1: "0",
      opt2: "0",
      opt3: "0",
      opt4: "0",
      opt5: "0",
      trapezium: "0",
      trapezoid: "0",
      capitate: "0",
      hamate: "0",
      scaphoid: "0",
      lunate: "0",
      triquetrum: "0",
      pisiform: "0",
      distalRadius: "0",
      distalUlna: "0",
    },
  },
  tenosynovitis: {
    MCPJoints: {
      opt1: "0",
      opt2: "0",
      opt3: "0",
      opt4: "0",
      opt5: "0",
    },
    wristJoints: {
      opt1: "0",
      opt2: "0",
      opt3: "0",
      opt4: "0",
      opt5: "0",
      opt6: "0",
      volarRadiocarpal: "0",
      ulnocarpal: "0",
      carpalTunnel: "0",
    },
  }
};

export const fieldsShouldHave03Options = [
  "synovitis.MCPJoints.opt1",
  "synovitis.MCPJoints.opt2",
  "synovitis.MCPJoints.opt3",
  "synovitis.MCPJoints.opt4",
  "synovitis.MCPJoints.opt5",
  "synovitis.wristJoints.distalRadioUlnarJoint",
  "synovitis.wristJoints.radioCarpalJoint",
  "synovitis.wristJoints.interCarpalCMCJ",
  "oedema.MCPJoints.proximal.opt1",
  "oedema.MCPJoints.proximal.opt2",
  "oedema.MCPJoints.proximal.opt3",
  "oedema.MCPJoints.proximal.opt4",
  "oedema.MCPJoints.proximal.opt5",
  "oedema.MCPJoints.distal.opt1",
  "oedema.MCPJoints.distal.opt2",
  "oedema.MCPJoints.distal.opt3",
  "oedema.MCPJoints.distal.opt4",
  "oedema.MCPJoints.distal.opt5",
  "oedema.wristJoints.op1",
  "oedema.wristJoints.op2",
  "oedema.wristJoints.op3",
  "oedema.wristJoints.op4",
  "oedema.wristJoints.op5",
  "oedema.wristJoints.opt1",
  "oedema.wristJoints.opt2",
  "oedema.wristJoints.opt3",
  "oedema.wristJoints.opt4",
  "oedema.wristJoints.opt5",
  "oedema.wristJoints.trapezium",
  "oedema.wristJoints.trapezoid",
  "oedema.wristJoints.capitate",
  "oedema.wristJoints.hamate",
  "oedema.wristJoints.scaphoid",
  "oedema.wristJoints.lunate",
  "oedema.wristJoints.triquetrum",
  "oedema.wristJoints.pisiform",
  "oedema.wristJoints.distalRadius",
  "oedema.wristJoints.distalUlna",
  "tenosynovitis.MCPJoints.opt1",
  "tenosynovitis.MCPJoints.opt2",
  "tenosynovitis.MCPJoints.opt3",
  "tenosynovitis.MCPJoints.opt4",
  "tenosynovitis.MCPJoints.opt5",
  "tenosynovitis.wristJoints.opt1",
  "tenosynovitis.wristJoints.opt2",
  "tenosynovitis.wristJoints.opt3",
  "tenosynovitis.wristJoints.opt4",
  "tenosynovitis.wristJoints.opt5",
  "tenosynovitis.wristJoints.opt6",
  "tenosynovitis.wristJoints.volarRadiocarpal",
  "tenosynovitis.wristJoints.ulnocarpal",
  "tenosynovitis.wristJoints.carpalTunnel",
];

export const fieldsShouldHave010Options = [
  "erosion.MCPJoints.proximal.opt1",
  "erosion.MCPJoints.proximal.opt2",
  "erosion.MCPJoints.proximal.opt3",
  "erosion.MCPJoints.proximal.opt4",
  "erosion.MCPJoints.proximal.opt5",
  "erosion.MCPJoints.distal.opt1",
  "erosion.MCPJoints.distal.opt2",
  "erosion.MCPJoints.distal.opt3",
  "erosion.MCPJoints.distal.opt4",
  "erosion.MCPJoints.distal.opt5",
  "erosion.wristJoints.op1",
  "erosion.wristJoints.op2",
  "erosion.wristJoints.op3",
  "erosion.wristJoints.op4",
  "erosion.wristJoints.op5",
  "erosion.wristJoints.opt1",
  "erosion.wristJoints.opt2",
  "erosion.wristJoints.opt3",
  "erosion.wristJoints.opt4",
  "erosion.wristJoints.opt5",
  "erosion.wristJoints.trapezium",
  "erosion.wristJoints.trapezoid",
  "erosion.wristJoints.capitate",
  "erosion.wristJoints.hamate",
  "erosion.wristJoints.scaphoid",
  "erosion.wristJoints.lunate",
  "erosion.wristJoints.triquetrum",
  "erosion.wristJoints.pisiform",
  "erosion.wristJoints.distalRadius",
  "erosion.wristJoints.distalUlna",
];
