import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import { ScoreSelectBox } from "../../ScoreSelectBox/ScoreSelectBox";
import { RamrisInputs } from "../../types";
import { FC } from "react";

type TenosynovitisWristJointsProps = {
    formName: keyof Omit<RamrisInputs, "visitId">;
};

type TableProps = {
    regPrefix: string;
};

export const Table1: FC<TableProps> = ({ regPrefix }) => {
    const names = {
        opt1: regPrefix + "opt1",
        opt2: regPrefix + "opt2",
        opt3: regPrefix + "opt3",
        opt4: regPrefix + "opt4",
        opt5: regPrefix + "opt5",
        opt6: regPrefix + "opt6",
    };
    const { register, formState } = useFormContext<RamrisInputs>();
    return (
        <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
            <tbody>
            <tr>
                <td className="border"></td>
                <td className="border" colSpan={5}>
                    <div className="w-full text-center">Dorsal Side</div>
                </td>
            </tr>
            <tr>
                <td rowSpan={2} className="border text-center">
                    Compartment Score (0 - 3)
                </td>
                <td className="border text-center">1</td>
                <td className="border text-center">2</td>
                <td className="border text-center">3</td>
                <td className="border text-center">4</td>
                <td className="border text-center">5</td>
                <td className="border text-center">6</td>
            </tr>
            <tr>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.opt1)}
                        {...register(names.opt1 as any, {
                            required: true,
                        })}
                    />
                </td>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.opt2)}
                        {...register(names.opt2 as any, {
                            required: true,
                        })}
                    />
                </td>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.opt3)}
                        {...register(names.opt3 as any, {
                            required: true,
                        })}
                    />
                </td>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.opt4)}
                        {...register(names.opt4 as any, {
                            required: true,
                        })}
                    />
                </td>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.opt5)}
                        {...register(names.opt5 as any, {
                            required: true,
                        })}
                    />
                </td>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.opt6)}
                        {...register(names.opt6 as any, {
                            required: true,
                        })}
                    />
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export const Table2: FC<TableProps> = ({ regPrefix }) => {
    const names = {
        volarRadiocarpal: regPrefix + "volarRadiocarpal",
        ulnocarpal: regPrefix + "ulnocarpal",
        carpalTunnel: regPrefix + "carpalTunnel",
    };
    const { register, formState } = useFormContext<RamrisInputs>();
    return (
        <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
            <tbody>
            <tr>
                <td className="border"></td>
                <td className="border" colSpan={5}>
                    <div className="w-full text-center">Volar Side</div>
                </td>
            </tr>
            <tr>
                <td rowSpan={2} className="border text-center">
                    Compartment Score (0 - 3)
                </td>
                <td className="border text-center">1. Volar Radiocarpal</td>
                <td className="border text-center">2. Ulnocarpal</td>
                <td className="border text-center">3. Carpal Tunnel</td>
            </tr>
            <tr>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.volarRadiocarpal)}
                        {...register(names.volarRadiocarpal as any, {
                            required: true,
                        })}
                    />
                </td>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.ulnocarpal)}
                        {...register(names.ulnocarpal as any, {
                            required: true,
                        })}
                    />
                </td>
                <td className="border text-center">
                    <ScoreSelectBox
                        type="0-3"
                        invalid={get(formState.errors, names.carpalTunnel)}
                        {...register(names.carpalTunnel as any, {
                            required: true,
                        })}
                    />
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export const TenosynovitisWristJoints: FC<TenosynovitisWristJointsProps> = ({
    formName,
}) => {
    const regPrefix = `${formName}.tenosynovitis.wristJoints.`;
    return (
        <div className="">
            <div className="text-white">OMERACT RAMRIS form for wrist joints</div>
            <div className="">
                <Table1 regPrefix={regPrefix} />
            </div>
            <div className="mt-2">
                <Table2 regPrefix={regPrefix} />
            </div>
        </div>
    );
};
