import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { RESTClient } from "../client";
import { GET_TOKEN } from "../contants";
import { useNavigate } from "react-router-dom";

export type makeMutationReadingResponse = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  "not-before-policy": number;
  session_state: string;
  scope: string;
};

type RequestArgs = {
  patientId: number;
  data: {
    visit: number;
    visit_name: string;
    result: string;
  };
  tempToken: string;
};

const makeMutationReading = async (values: RequestArgs) => {

  const headers = {
    'X-One-Time-Token': values.tempToken};

  const { data } = await RESTClient.post<makeMutationReadingResponse>(
    `/reading/${values.patientId}`,
    values.data,
      { headers }
  );

  return data;
};

export const useMutationReading = () => {
  const navigate = useNavigate();
  const processor = useCallback(
    (args: RequestArgs) => makeMutationReading(args),
    []
  );
  const {
    mutateAsync,
    data,
    isLoading: loading,
  } = useMutation([GET_TOKEN], processor, {
    retry: false,
    onError: (error) => {
      console.log(error);


      // navigate(routesPath.login);
    },
  });

  return { sendReading: mutateAsync, data, loading };
};
