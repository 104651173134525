import React, { FC, useState, useEffect } from "react";
import classNames from "clsx";

type ReAuthenticationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (username: string, password: string) => void;
};

export const ReAuthenticationModal: FC<ReAuthenticationModalProps> = ({
                                                                          isOpen,
                                                                          onClose,
                                                                          onConfirm,
                                                                      }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const isFormValid = username && password && isCheckboxChecked;

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };

    const handleConfirmClick = () => {
        if (isFormValid) {
            onConfirm(username, password);
            onClose();
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setUsername("");
            setPassword("");
            setIsCheckboxChecked(false);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-lg">
                <h5 className="text-base font-semibold mb-4 text-center">Image Analysis Group requires you to authenticate<br/> your signature on this document <br/></h5>
                <h5 className="text-base font-semibold mb-4 text-center">Please enter your login credentials</h5>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Username
                    </label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm p-2"
                        required
                        style={{ height: "40px" }} // Increase height
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Password
                    </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm p-2"
                        required
                        style={{ height: "40px" }} // Increase height
                    />
                </div>
                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                            className="form-checkbox h-5 w-5 text-black"
                        />
                        <span className="ml-2 text-gray-700">
              I confirm the data recorded and approve the report.
            </span>
                    </label>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="mr-4 px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-200"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirmClick}
                        disabled={!isFormValid}
                        className={classNames(
                            "px-4 py-2 rounded-md text-white",
                            isFormValid ? "bg-black hover:bg-gray-800" : "bg-gray-400 cursor-not-allowed"
                        )}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};
