import { FC, useCallback, useMemo, useState } from "react";
import { useQueryPatients } from "../../api/query/useQueryPatients";
import { useQueryVisits } from "../../api/query/useQueryVisits";
import { useMutationReading } from "../../api/mutation/useMutationReading";
import { FormShape, RamrisInputs } from "../../components/RamrisForm/types";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { GET_PATIENTS, GET_VISITS } from "../../api/contants";
import { HomePageView } from "./Home.view";
import { useQueryTempToken } from "../../api/query/useQueryTempToken";

export const HomePage: FC = () => {
    const client = useQueryClient();
    const [currentPatient, setCurrentPatient] = useState<number | undefined>();
    const { data: patients, loading: patientsLoading } = useQueryPatients();
    const { data: visits, loading: visitsLoading } = useQueryVisits(currentPatient);

    const { sendReading } = useMutationReading();
    const { tempToken, token } = useQueryTempToken();

    const patientOptions = useMemo(
        () =>
            (patients || []).map((patient) => ({
                label: patient.patient_id,
                value: patient.id,
            })),
        [patients]
    );

    const visitsOptions = useMemo(
        () =>
            (visits || []).map((visit) => ({
                label: visit.fake_name,
                value: visit.id,
            })),
        [visits]
    );

    const selectPatient = useCallback((value: number) => {
        setCurrentPatient(value);
    }, []);

    function sumNumericFields(data: any): number {
        let sum = 0;

        function recursiveSum(obj: any) {
            for (const key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    recursiveSum(obj[key]);
                } else if (!isNaN(Number(obj[key]))) {
                    sum += Number(obj[key]);
                }
            }
        }

        recursiveSum(data);
        return sum;
    }

    function calculateDamageScore(data: FormShape) {
        return sumNumericFields(data["erosion"]);
    }

    function calculateInflammationScore(data: FormShape) {
        return (
            sumNumericFields(data["synovitis"]) +
            sumNumericFields(data["oedema"]) +
            sumNumericFields(data["tenosynovitis"])
        );
    }

    const submitData = useCallback(
        async (data: RamrisInputs, username: string, password: string) => {
            data.form1.damageScore = calculateDamageScore(data.form1).toString();
            data.form1.inflammationScore = calculateInflammationScore(data.form1).toString();

            try {
                const tokenResult1 = await tempToken({
                    username,
                    password,
                    payload: {
                        visit: data.visit1,
                        result: JSON.stringify(data.form1),
                    },
                });

                console.log("TOKEN");
                console.log(tokenResult1);
                if (!tokenResult1) throw new Error("Token is not available");

                const f1 = sendReading({
                    data: {
                        visit: data.visit1,
                        visit_name: data.visit1Name,
                        result: JSON.stringify(data.form1),
                    },
                    patientId: currentPatient || 0,
                    tempToken: tokenResult1, // Use the returned token
                }).then(() => {
                    toast.success("Form 1 submitted");
                })
                    .catch((e) => {
                        console.log("ERROR");
                        console.log(e);
                        toast.error(`Form 1 submission failed: ${e.response.data}`);
                        throw e;
                    });

                await f1;
                //toast.success("Form 1 submitted");

                data.form2.damageScore = calculateDamageScore(data.form2).toString();
                data.form2.inflammationScore = calculateInflammationScore(data.form2).toString();

                const tokenResult2 = await tempToken({
                    username,
                    password,
                    payload: {
                        visit: data.visit2,
                        result: JSON.stringify(data.form2),
                    },
                });

                if (!tokenResult2) throw new Error("Token is not available");

                const f2 = sendReading({
                    data: {
                        visit: data.visit2,
                        visit_name: data.visit2Name,
                        result: JSON.stringify(data.form2),
                    },
                    patientId: currentPatient || 0,
                    tempToken: tokenResult2, // Use the returned token
                }).then(() => {
                    toast.success("Form 2 submitted");
                })
                    .catch((e) => {
                        console.log("ERROR");
                        console.log(e);
                        toast.error(`Form 2 submission failed: ${e.response.data}`);
                        throw e;
                    });

                await f2;
                //toast.success("Form 2 submitted");

                await Promise.allSettled([f1, f2]).then((p) => {
                    client.invalidateQueries([GET_PATIENTS]);
                    client.invalidateQueries([GET_VISITS, currentPatient]);
                    setCurrentPatient(undefined);
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                    }, 0);
                    return p;
                });
            } catch (e: any) {
                toast.error(e.response.data);
            }
        },
        [currentPatient, sendReading, tempToken, client]
    );

    return (
        <HomePageView
            currentPatient={currentPatient}
            onSelectPatient={selectPatient}
            onSubmit={submitData}
            patientsLoading={patientsLoading}
            visitsLoading={visitsLoading}
            patientOptions={patientOptions}
            visitsOptions={visitsOptions}
        />
    );
};
