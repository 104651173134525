/* eslint-disable no-console */
import axios, { AxiosError } from "axios";
import { API_BASE_URL } from "../env";

import { toast } from "react-toastify";
import { routesPath } from "../routes";

const callToast = (error: AxiosError) => {
  const history = window.history;

  if (error.response) {
    if (error.response.status === 401 && error.config && !error.config?.url?.includes("/token/tmp")) {
      localStorage.removeItem("token");
    }
  } else if (error.request) {
    console.error(error.request);
  } else {
    console.error("Error", error.message);
  }
  console.log(error.config);

  if (error) {
    let message = error?.message || "Error";

    if (/doctype/.test(message)) {
      message = "Oops! Something went wrong!";
    }
    toast.error(message, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }

  return Promise.reject(error);
};

export const RESTClient = axios.create({
  baseURL: API_BASE_URL + "/api/v1",

  transformResponse: [
    (data) => {
      if (typeof data === "string") {
        return JSON.parse(data.replace(/(-?)Infinity|NaN/g, "0"));
      }
      return data;
    },
  ],
});

RESTClient.interceptors.request.use((config: any) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

RESTClient.interceptors.response.use((response) => response, callToast);
