import { FC } from "react";
import classNames from "clsx";
import { Select } from "antd";
import { RamrisForm } from "../../components/RamrisForm/RamrisForm";
import { RamrisInputs } from "../../components/RamrisForm/types";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { Option } from "../../types";

type HomePageViewProps = {
  patientsLoading?: boolean;
  visitsLoading?: boolean;
  onSelectPatient: (id: number) => void;
  onSubmit: (data: RamrisInputs, username: string, password: string) => Promise<any>;
  patientOptions: Option[];
  currentPatient?: number | null;
  visitsOptions: Option[];
};

export const HomePageView: FC<HomePageViewProps> = ({
  patientsLoading,
  visitsLoading,
  onSelectPatient,
  onSubmit,
  patientOptions,
  currentPatient,
  visitsOptions,
}) => {
  return (
    <div className="flex flex-col relative">
      <div
        className={classNames("fixed w-full h-full z-20", {
          hidden: !(patientsLoading || (visitsLoading && currentPatient)),
        })}
      >
        <div className="w-full h-full bg-black opacity-70 left-0 right-0 top-0 bottom-0 z-20"></div>
        <LoadingSpinner />
      </div>

      <div className="flex items-center mr-2 p-4">
        <div className="mr-2">Patient ID</div>
        <Select
          showSearch
          placeholder="Select a patient"
          optionFilterProp="children"
          value={currentPatient}
          onChange={onSelectPatient}
          className="min-w-[10rem]"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={patientOptions}
        />
      </div>

      <RamrisForm
        disabled={!currentPatient || visitsLoading}
        key={currentPatient}
        visits={visitsOptions}
        onSubmit={onSubmit}
      />
    </div>
  );
};
