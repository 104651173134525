import { FC } from "react";
import get from "lodash/get";
import { useFormContext } from "react-hook-form";
import { ScoreSelectBox } from "../../ScoreSelectBox/ScoreSelectBox";
import { RamrisInputs } from "../../types";

type OedemaMCPJointsProps = {
  formName: keyof Omit<RamrisInputs, "visitId">;
};

export const OedemaMCPJoints: FC<OedemaMCPJointsProps> = ({ formName }) => {
  const regPrefix = `${formName}.oedema.MCPJoints.`;

  const names = {
    proximal: {
      opt1: regPrefix + "proximal.opt1",
      opt2: regPrefix + "proximal.opt2",
      opt3: regPrefix + "proximal.opt3",
      opt4: regPrefix + "proximal.opt4",
      opt5: regPrefix + "proximal.opt5",
    },
    distal: {
      opt1: regPrefix + "distal.opt1",
      opt2: regPrefix + "distal.opt2",
      opt3: regPrefix + "distal.opt3",
      opt4: regPrefix + "distal.opt4",
      opt5: regPrefix + "distal.opt5",
    },
  };
  const { register, formState } = useFormContext<RamrisInputs>();
  return (
    <div className="">
      <div className="text-white">OMERACT RAMRIS form for MCP joints</div>
      <div className="">
        <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
          <tbody>
            <tr>
              <td className="border"></td>
              <td rowSpan={2} className="border"></td>
              <td className="border" colSpan={4}>
                <div className="w-full text-center">MCP - joints</div>
              </td>
            </tr>
            <tr>
              <td rowSpan={3} className="border text-center">
                Bone oedema (0 - 3)
              </td>
              <td className="border text-center">1</td>
              <td className="border text-center">2</td>
              <td className="border text-center">3</td>
              <td className="border text-center">4</td>
              <td className="border text-center">5</td>
            </tr>
            <tr>
              <td className="border text-center">Proximal</td>
              <td className="border text-center">
                <ScoreSelectBox
                    type="0-3"
                    invalid={get(formState.errors, names.proximal.opt1)}
                    {...register(names.proximal.opt1 as any, {
                      required: true,
                    })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.proximal.opt2)}
                  {...register(names.proximal.opt2 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.proximal.opt3)}
                  {...register(names.proximal.opt3 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.proximal.opt4)}
                  {...register(names.proximal.opt4 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.proximal.opt5)}
                  {...register(names.proximal.opt5 as any, {
                    required: true,
                  })}
                />
              </td>
            </tr>
            <tr>
              <td className="border text-center">Distal</td>
              <td className="border text-center">
                <ScoreSelectBox
                    type="0-3"
                    invalid={get(formState.errors, names.distal.opt1)}
                    {...register(names.distal.opt1 as any, {
                      required: true,
                    })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.distal.opt2)}
                  {...register(names.distal.opt2 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.distal.opt3)}
                  {...register(names.distal.opt3 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.distal.opt4)}
                  {...register(names.distal.opt4 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.distal.opt5)}
                  {...register(names.distal.opt5 as any, {
                    required: true,
                  })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
