import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import { ScoreSelectBox } from "../../ScoreSelectBox/ScoreSelectBox";
import { RamrisInputs } from "../../types";
import { FC } from "react";

type ErosionWristJointsProps = {
  formName: keyof Omit<RamrisInputs, "visitId">;
};

type TableProps = {
  regPrefix: string;
};

export const Table0: FC<TableProps> = ({ regPrefix }) => {
  const names = {
    op1: regPrefix + "op1",
    op2: regPrefix + "op2",
    op3: regPrefix + "op3",
    op4: regPrefix + "op4",
    op5: regPrefix + "op5",
  };
  const { register, formState } = useFormContext<RamrisInputs>();
  return (
      <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
        <tbody>
        <tr>
          <td className="border"></td>
          <td className="border" colSpan={5}>
            <div className="w-full text-center">Base of phalanges</div>
          </td>
        </tr>
        <tr>
          <td rowSpan={2} className="border text-center">
            Score (0 - 10)
          </td>
          <td className="border text-center">1</td>
          <td className="border text-center">2</td>
          <td className="border text-center">3</td>
          <td className="border text-center">4</td>
          <td className="border text-center">5</td>
        </tr>
        <tr>
          <td className="border text-center">
            <ScoreSelectBox
                type="0-10"
                invalid={get(formState.errors, names.op1)}
                {...register(names.op1 as any, {
                  required: true,
                })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
                type="0-10"
                invalid={get(formState.errors, names.op2)}
                {...register(names.op2 as any, {
                  required: true,
                })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
                type="0-10"
                invalid={get(formState.errors, names.op3)}
                {...register(names.op3 as any, {
                  required: true,
                })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
                type="0-10"
                invalid={get(formState.errors, names.op4)}
                {...register(names.op4 as any, {
                  required: true,
                })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
                type="0-10"
                invalid={get(formState.errors, names.op5)}
                {...register(names.op5 as any, {
                  required: true,
                })}
            />
          </td>
        </tr>
        </tbody>
      </table>
  );
};

export const Table1: FC<TableProps> = ({ regPrefix }) => {
  const names = {
    opt1: regPrefix + "opt1",
    opt2: regPrefix + "opt2",
    opt3: regPrefix + "opt3",
    opt4: regPrefix + "opt4",
    opt5: regPrefix + "opt5",
  };
  const { register, formState } = useFormContext<RamrisInputs>();
  return (
    <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
      <tbody>
        <tr>
          <td className="border"></td>
          <td className="border" colSpan={5}>
            <div className="w-full text-center">Base of metacarpal</div>
          </td>
        </tr>
        <tr>
          <td rowSpan={2} className="border text-center">
            Bone erosion (0 - 10)
          </td>
          <td className="border text-center">1</td>
          <td className="border text-center">2</td>
          <td className="border text-center">3</td>
          <td className="border text-center">4</td>
          <td className="border text-center">5</td>
        </tr>
        <tr>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.opt1)}
              {...register(names.opt1 as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.opt2)}
              {...register(names.opt2 as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.opt3)}
              {...register(names.opt3 as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.opt4)}
              {...register(names.opt4 as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.opt5)}
              {...register(names.opt5 as any, {
                required: true,
              })}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export const Table2: FC<TableProps> = ({ regPrefix }) => {
  const names = {
    trapezium: regPrefix + "trapezium",
    trapezoid: regPrefix + "trapezoid",
    capitate: regPrefix + "capitate",
    hamate: regPrefix + "hamate",
  };
  const { register, formState } = useFormContext<RamrisInputs>();
  return (
    <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
      <tbody>
        <tr>
          <td rowSpan={2} className="border text-center">
            Bone erosion (0 - 10)
          </td>
          <td className="border text-center">Trapezium</td>
          <td className="border text-center">Trapezoid</td>
          <td className="border text-center">Capitate</td>
          <td className="border text-center">Hamate</td>
        </tr>
        <tr>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.trapezium)}
              {...register(names.trapezium as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.trapezoid)}
              {...register(names.trapezoid as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.capitate)}
              {...register(names.capitate as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.hamate)}
              {...register(names.hamate as any, {
                required: true,
              })}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const Table3: FC<TableProps> = ({ regPrefix }) => {
  const names = {
    scaphoid: regPrefix + "scaphoid",
    lunate: regPrefix + "lunate",
    triquetrum: regPrefix + "triquetrum",
    pisiform: regPrefix + "pisiform",
  };
  const { register, formState } = useFormContext<RamrisInputs>();
  return (
    <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
      <tbody>
        <tr>
          <td rowSpan={2} className="border text-center">
            Bone erosion (0 - 10)
          </td>
          <td className="border text-center">Scaphoid</td>
          <td className="border text-center">Lunate</td>
          <td className="border text-center">Triquetrum</td>
          <td className="border text-center">Pisiform</td>
        </tr>
        <tr>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.scaphoid)}
              {...register(names.scaphoid as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.lunate)}
              {...register(names.lunate as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.triquetrum)}
              {...register(names.triquetrum as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.pisiform)}
              {...register(names.pisiform as any, {
                required: true,
              })}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export const Table4: FC<TableProps> = ({ regPrefix }) => {
  const names = {
    distalRadius: regPrefix + "distalRadius",
    distalUlna: regPrefix + "distalUlna",
  };
  const { register, formState } = useFormContext<RamrisInputs>();
  return (
    <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
      <tbody>
        <tr>
          <td rowSpan={2} className="border text-center">
            Bone erosion (0 - 10)
          </td>
          <td className="border text-center">Distal radius</td>
          <td className="border text-center">Distal ulna</td>
        </tr>
        <tr>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.distalRadius)}
              {...register(names.distalRadius as any, {
                required: true,
              })}
            />
          </td>
          <td className="border text-center">
            <ScoreSelectBox
              type="0-10"
              invalid={get(formState.errors, names.distalUlna)}
              {...register(names.distalUlna as any, {
                required: true,
              })}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const ErosionWristJoints: FC<ErosionWristJointsProps> = ({
  formName,
}) => {
  const regPrefix = `${formName}.erosion.wristJoints.`;
  return (
    <div className="">
      <div className="text-white">OMERACT RAMRIS form for wrist joints</div>
      <div className="">
        <Table0 regPrefix={regPrefix} />
      </div>
      <div className="mt-2">
        <Table1 regPrefix={regPrefix} />
      </div>
      <div className="mt-2">
        <Table2 regPrefix={regPrefix} />
      </div>
      <div className="mt-2">
        <Table3 regPrefix={regPrefix} />
      </div>
      <div className="mt-2">
        <Table4 regPrefix={regPrefix} />
      </div>
    </div>
  );
};
