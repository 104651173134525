import { FormShape, RamrisInputs } from "../../types";
import { FC } from "react";
import { OedemaMCPJoints } from "./OedemaMCPJoints";
import { OedemaWristJoints } from "./OedemaWristJoints";
import { FillWithZeroButton } from "../../../FillWithZeroButton/FillWithZeroButton";

type OedemaProps = {
  formName: keyof Omit<RamrisInputs, "visitId">;
  onFillByZero: (
    formName: keyof Omit<RamrisInputs, "visitId">,
    sections: keyof FormShape
  ) => void;
};

export const Oedema: FC<OedemaProps> = ({ formName, onFillByZero }) => {
  return (
    <div className="flex flex-col">
      <div className="">
        <div className="bg-white inline-block px-2 rounded-sm">Oedema</div>
      </div>
      <div className="my-4">
        <OedemaMCPJoints formName={formName} />
      </div>
      <div className="">
        <OedemaWristJoints formName={formName} />
      </div>
      <div className="mt-2 self-end">
        <FillWithZeroButton onClick={() => onFillByZero(formName, "oedema")} />
      </div>
    </div>
  );
};
