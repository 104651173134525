import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import { ScoreSelectBox } from "../../ScoreSelectBox/ScoreSelectBox";
import { RamrisInputs } from "../../types";
import { FC } from "react";

type SynovitisWristJointsProps = {
  formName: keyof Omit<RamrisInputs, "visitId">;
};

export const SynovitisWristJoints: FC<SynovitisWristJointsProps> = ({
  formName,
}) => {
  const regPrefix = `${formName}.synovitis.wristJoints.`;
  const names = {
    distalRadioUlnarJoint: regPrefix + "distalRadioUlnarJoint",
    radioCarpalJoint: regPrefix + "radioCarpalJoint",
    interCarpalCMCJ: regPrefix + "interCarpalCMCJ",
  };
  const { register, formState } = useFormContext<RamrisInputs>();
  return (
    <div className="">
      <div className="text-white">OMERACT RAMRIS form for wrist joints</div>
      <div className="">
        <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
          <tbody>
            <tr>
              <td rowSpan={2} className="border text-center">
                Synovitis (0 - 3)
              </td>
              <td className="border text-center">Distal radio-ulnar joint</td>
              <td className="border text-center">Radio-carpal joint</td>
              <td className="border text-center">Intercarpal-CMCJ</td>
            </tr>
            <tr>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.distalRadioUlnarJoint)}
                  {...register(names.distalRadioUlnarJoint as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.radioCarpalJoint)}
                  {...register(names.radioCarpalJoint as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.interCarpalCMCJ)}
                  {...register(names.interCarpalCMCJ as any, {
                    required: true,
                  })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
