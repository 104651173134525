import { FormProvider, useForm } from "react-hook-form";
import classNames from "clsx";
import { get } from "lodash";
import { Synovitis } from "./sections/Synovitis/Synovitis";
import { FormShape, RamrisInputs } from "./types";
import { FC, useCallback, useEffect, useState } from "react";
import { Erosion } from "./sections/Erosion/Erosion";
import { Oedema } from "./sections/Oedema/Oedema";
import { defaultForm } from "./contants";
import { Option } from "../../types";
import { Tenosynovitis } from "./sections/Tenosynovitis/Tenosynovitis";
import { ReAuthenticationModal } from "../ReAuthenticationModal/ReAuthenticationModal";

type RamrisFormProps = {
  visits: Option[];
  disabled?: boolean;
  onSubmit?: (
      data: RamrisInputs,
      username: string,
      password: string
  ) => Promise<[PromiseSettledResult<void>, PromiseSettledResult<void>]>;
  onChange?: (data: RamrisInputs) => void;
};

export const RamrisForm: FC<RamrisFormProps> = ({
                                                  onSubmit,
                                                  onChange,
                                                  disabled,
                                                  visits,
                                                }) => {
  const methods = useForm<RamrisInputs>({
    defaultValues: {
      visit1: 0,
      visit2: 0,
      visit1Name: "",
      visit2Name: "",
      form1: defaultForm,
      form2: defaultForm,
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const fillByZero = useCallback(
      (
          formName: keyof Omit<RamrisInputs, "visitId">,
          section: keyof FormShape
      ) => {
        const sectionWithZeros = get<FormShape[typeof section]>(
            defaultForm,
            section as any
        );

        methods.setValue(`${formName}.${section}` as any, sectionWithZeros);
      },
      [methods]
  );

  useEffect(() => {
    const remListener = methods.watch((state, changedData) => {
      if (changedData.name === "visit1" || changedData.name === "visit2")
        return;
      onChange?.(methods.getValues());

      /*if (changedData.name?.includes("form2")) return;
      const value = get(state, changedData.name as string);
      if (value && changedData.name) {
        methods.setValue(
            changedData.name?.replace("form1", "form2") as any,
            value
        );
      }*/
    });

    return () => {
      remListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (visits.length > 0) {
      const left = visits.find((v) => /left/i.test(String(v.label)));
      methods.setValue("visit1", (left?.value || visits[0].value) as number);
    }
    if (visits.length > 1) {
      const right = visits.find((v) => /right/i.test(String(v.label)));
      methods.setValue("visit2", (right?.value || visits[1].value) as number);
    }
  }, [visits]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmAction = async (username: string, password: string) => {
    const data = methods.getValues();
    const res = await onSubmit?.(data, username, password);

    if (res?.[0].status === "fulfilled") {
      methods.setValue("form1", defaultForm);
    }
    if (res?.[1].status === "fulfilled") {
      methods.setValue("form2", defaultForm);
    }

    handleCloseModal(); // Close modal after action
  };

  const submit = async (data: RamrisInputs) => {
    handleOpenModal(); // Open modal on form submit
  };

  const visit1 = visits.find((v) => v.value === methods.watch("visit1"))?.label;
  const visit2 = visits.find((v) => v.value === methods.watch("visit2"))?.label;

  return (
      <div className="relative">
        <ReAuthenticationModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onConfirm={handleConfirmAction} // Pass handleConfirmAction to onConfirm
        />
        <div
            data-testid="shadow-blocker-form"
            className={classNames("absolute w-full h-full z-10", {
              hidden: !disabled,
            })}
        >
          <div className="w-full h-full bg-black opacity-70 left-0 right-0 top-0 bottom-0 z-10"></div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submit)}>
            <div className="flex flex-col">
              <div className="flex justify-between p-2 bg-gray-950">
                <div data-testid="form1" className="w-full mr-4">
                  <div className="w-full flex items-center justify-center">
                    {/*<div className="bg-white inline-block px-2 rounded-sm">
                      {visit1}
                    </div>*/}
                    <div className="w-full flex items-center justify-center">
                      <input
                          type="text"
                          className="bg-white inline-block px-2 rounded-sm"
                          {...methods.register("visit1Name")}
                          placeholder="Visit Name"
                      />
                    </div>
                  </div>
                  <div className="">
                    <Synovitis onFillByZero={fillByZero} formName="form1" />
                  </div>
                  <div className="">
                    <Erosion onFillByZero={fillByZero} formName="form1" />
                  </div>
                  <div className="">
                    <Oedema onFillByZero={fillByZero} formName="form1" />
                  </div>
                  <div className="">
                    <Tenosynovitis onFillByZero={fillByZero} formName="form1" />
                  </div>
                </div>
                <div data-testid="form2" className="w-full ml-4">
                  <div className="w-full flex items-center justify-center">
                   {/* <div className="bg-white inline-block px-2 rounded-sm">
                      {visit2}
                    </div>*/}
                    <div className="w-full flex items-center justify-center">
                      <input
                          type="text"
                          className="bg-white inline-block px-2 rounded-sm"
                          {...methods.register("visit2Name")}
                          placeholder="Visit Name"
                      />
                    </div>
                  </div>
                  <div className="">
                    <Synovitis onFillByZero={fillByZero} formName="form2" />
                  </div>
                  <div className="">
                    <Erosion onFillByZero={fillByZero} formName="form2" />
                  </div>
                  <div className="">
                    <Oedema onFillByZero={fillByZero} formName="form2" />
                  </div>
                  <div className="">
                    <Tenosynovitis onFillByZero={fillByZero} formName="form2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center py-4">
              <button
                  type="submit"
                  disabled={disabled}
                  className={classNames(
                      "border bg-gray-950 text-white rounded-md px-4 py-1",
                      {
                        "bg-gray-400 cursor-not-allowed": disabled,
                      }
                  )}
              >
                Submit
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
  );
};
