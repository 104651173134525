import { FormShape, RamrisInputs } from "../../types";
import { FC } from "react";
import { FillWithZeroButton } from "../../../FillWithZeroButton/FillWithZeroButton";
import {TenosynovitisMCPJoints} from "./TenosynovitisMCPJoints";
import {TenosynovitisWristJoints} from "./TenosynovitisWristJoints";

type TenosynovitisProps = {
    formName: keyof Omit<RamrisInputs, "visitId">;
    onFillByZero: (
        formName: keyof Omit<RamrisInputs, "visitId">,
        sections: keyof FormShape
    ) => void;
};

export const Tenosynovitis: FC<TenosynovitisProps> = ({ formName, onFillByZero }) => {
    return (
        <div className="flex flex-col">
            <div className="">
                <div className="bg-white inline-block px-2 rounded-sm">Tenosynovitis</div>
            </div>
            <div className="my-4">
                <TenosynovitisMCPJoints formName={formName} />
            </div>
            <div className="">
                <TenosynovitisWristJoints formName={formName} />
            </div>
            <div className="mt-2 self-end">
                <FillWithZeroButton onClick={() => onFillByZero(formName, "tenosynovitis")} />
            </div>
        </div>
    );
};
