import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import { ScoreSelectBox } from "../../ScoreSelectBox/ScoreSelectBox";
import { RamrisInputs } from "../../types";
import { FC } from "react";

type SynovitisMCPJointsProps = {
  formName: keyof Omit<RamrisInputs, "visitId">;
};

export const SynovitisMCPJoints: FC<SynovitisMCPJointsProps> = ({
  formName,
}) => {
  const regPrefix = `${formName}.synovitis.MCPJoints.`;
  const names = {
    opt1: regPrefix + "opt1",
    opt2: regPrefix + "opt2",
    opt3: regPrefix + "opt3",
    opt4: regPrefix + "opt4",
    opt5: regPrefix + "opt5",
  };
  const { register, formState } = useFormContext<RamrisInputs>();

  return (
    <div className="">
      <div className="text-white">OMERACT RAMRIS form for MCP joints</div>
      <div className="">
        <table className="bg-white w-full border-collapse border border-slate-400 table-auto">
          <tbody>
            <tr>
              <td className="border"></td>
              <td className="border" colSpan={5}>
                <div className="w-full text-center">MCP - joints</div>
              </td>
            </tr>
            <tr>
              <td rowSpan={2} className="border text-center">
                Synovitis (0 - 3)
              </td>
              <td className="border text-center">1</td>
              <td className="border text-center">2</td>
              <td className="border text-center">3</td>
              <td className="border text-center">4</td>
              <td className="border text-center">5</td>
            </tr>
            <tr>
              <td className="border text-center">
                <ScoreSelectBox
                    type="0-3"
                    invalid={get(formState.errors, names.opt1)}
                    {...register(names.opt1 as any, {
                      required: true,
                    })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.opt2)}
                  {...register(names.opt2 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.opt3)}
                  {...register(names.opt3 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.opt4)}
                  {...register(names.opt4 as any, {
                    required: true,
                  })}
                />
              </td>
              <td className="border text-center">
                <ScoreSelectBox
                  type="0-3"
                  invalid={get(formState.errors, names.opt5)}
                  {...register(names.opt5 as any, {
                    required: true,
                  })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
