import { useState, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { RESTClient } from "../client";
import { GET_TOKEN } from "../contants";


type RequestArgs = {
    username: string;
    password: string;
    payload: {};
};

const makeQueryTempToken = async (values: RequestArgs) => {
    const { data } = await RESTClient.post<string>(
        `/token/tmp`,
        { username: values.username, password: values.password, payload: values.payload }
    );

    return data;
};

export const useQueryTempToken = () => {
    const [token, setToken] = useState<string | null>(null);

    const processor = useCallback(
        async (args: RequestArgs) => {
            const result = await makeQueryTempToken(args);
            setToken(result); // Store the token in state
            return result; // Return the token directly
        },
        []
    );

    const { mutateAsync, data } = useMutation([GET_TOKEN], processor, {
        retry: false,
        onError: (error) => {
            console.log(error);
        },
    });

    return { tempToken: mutateAsync, data, token }; // Return the token state
};
