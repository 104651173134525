import { FormShape, RamrisInputs } from "../../types";
import { FC } from "react";
import { ErosionMCPJoints } from "./ErosionMCPJoints";
import { ErosionWristJoints } from "./ErosionWristJoints";
import { FillWithZeroButton } from "../../../FillWithZeroButton/FillWithZeroButton";

type ErosionProps = {
  formName: keyof Omit<RamrisInputs, "visitId">;
  onFillByZero: (
    formName: keyof Omit<RamrisInputs, "visitId">,
    sections: keyof FormShape
  ) => void;
};

export const Erosion: FC<ErosionProps> = ({ formName, onFillByZero }) => {
  return (
    <div className="flex flex-col">
      <div className="">
        <div className="bg-white inline-block px-2 rounded-sm">Erosion</div>
      </div>
      <div className="my-4">
        <ErosionMCPJoints formName={formName} />
      </div>
      <div className="">
        <ErosionWristJoints formName={formName} />
      </div>
      <div className="mt-2 self-end">
        <FillWithZeroButton onClick={() => onFillByZero(formName, "erosion")} />
      </div>
    </div>
  );
};
