import { FC } from 'react';

export const LoadingSpinner: FC<{ size?: number }> = ({ size = 40 }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <style>
        {`
        .ORA-loading-spinner {
          animation: rotate 2s linear infinite;
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -25px 0 0 -25px;
          width: ${size}px;
          height: ${size}px;
        }
        .ORA-loading-spinner .ORA-loading-spinner-path {
          stroke: white;
          stroke-linecap: round;
          animation: dash 1.5s ease-in-out infinite;
        }

            @keyframes rotate {
              100% {
                transform: rotate(360deg);
              }
            }

            @keyframes dash {
              0% {
                stroke-dasharray: 1, 150;
                stroke-dashoffset: 0;
              }
              50% {
                stroke-dasharray: 90, 150;
                stroke-dashoffset: -35;
              }
              100% {
                stroke-dasharray: 90, 150;
                stroke-dashoffset: -124;
              }
            }
        `}
      </style>
      <svg className="ORA-loading-spinner" viewBox="0 0 50 50">
        <circle
          className="ORA-loading-spinner-path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    </div>
  );
};
