import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { RESTClient } from "../client";
import { GET_VISITS } from "../contants";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { routesPath } from "../../routes";

export type useQueryVisitsResponse = {
  id: number;
  patient_id: string;
  fake_name: string;
}[];

export type CaseArgs = {
  date: string;
  rooms?: string[] | null;
};

const makeQueryVisits = async (patientId?: number) => {
  const { data } = await RESTClient.get<useQueryVisitsResponse>(
    `/visits/${patientId}`
  );

  return data;
};

export const useQueryVisits = (patientId?: number) => {
  const navigate = useNavigate();
  const processor = useCallback(() => makeQueryVisits(patientId), [patientId]);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_VISITS, patientId], processor, {
    enabled: !!patientId,
    onError: (error: AxiosError) => {
      if (error.response?.status === 401) {
        navigate(routesPath.login);
      }
    },
  });

  return { data: data || [], loading, refetch };
};
