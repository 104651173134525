import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { RESTClient } from "../client";
import { GET_LOGIN } from "../contants";
import { AxiosError, AxiosResponse } from "axios";

export type useQueryLoginResponse = {
  url: string;
};

const makeQueryLogin = async (params: Record<string, any>) => {
  const { data } = await RESTClient.get<useQueryLoginResponse>(`/login`, {
    params,
  });

  return data;
};

export const useQueryLogin = (enabled?: boolean) => {
  const processor = useCallback(() => makeQueryLogin({}), []);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_LOGIN], processor, {
    useErrorBoundary: (error: AxiosError) => error.response?.status === 401,
    enabled,
  });

  return { data, loading, refetch };
};
