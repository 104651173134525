import { FC } from "react";

type FillWithZeroButtonProps = {
  onClick: () => void;
};

export const FillWithZeroButton: FC<FillWithZeroButtonProps> = ({
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="text-white border rounded-md px-1 hover:bg-blue-950"
    >
      Fill all cells in section with zero
    </button>
  );
};
