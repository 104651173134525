import "./App.css";
import {
  redirect,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { HomePage } from "./pages/Home/Home";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { routesPath } from "./routes";
import { LoginPage } from "./pages/Login/Login";
import { AxiosError } from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: 3000,
      retry: (count, error) => {
        const status = (error as AxiosError).response?.status;
        if (status === 401 || status === 403) {
          return false;
        }
        return count < 3;
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: routesPath.home,
    loader: async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        return redirect(routesPath.login);
      }

      return true;
    },
    element: <HomePage />,
  },
  {
    path: routesPath.login,
    element: <LoginPage />,
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
