import { useEffect } from "react";
import { useQueryLogin } from "../../api/query/useQueryLogin";
import { useQueryToken } from "../../api/query/useQueryToken";
import { useNavigate } from "react-router-dom";
import { routesPath } from "../../routes";

export const LoginPage = () => {
  const navigate = useNavigate();
  const search = new URLSearchParams(window.location.search);
  const { data: loginData } = useQueryLogin(!search.get("code"));
  const { data: tokenData } = useQueryToken({
    code: search.get("code") || "",
  });

  useEffect(() => {
    if (loginData?.url) {
      window.location.replace(loginData.url);
    }
  }, [loginData]);
  useEffect(() => {
    localStorage.setItem("token", tokenData?.access_token || "");

    setTimeout(() => {
      navigate(routesPath.home);
    }, 1000);
  }, [tokenData]);

  return null;
};
