import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { RESTClient } from "../client";
import { GET_TOKEN } from "../contants";
import { routesPath } from "../../routes";
import { useNavigate } from "react-router-dom";

export type useQueryTokenResponse = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  "not-before-policy": number;
  session_state: string;
  scope: string;
};

type RequestArgs = {
  code: string;
};

const makeQueryToken = async (params: RequestArgs) => {
  const { data } = await RESTClient.get<useQueryTokenResponse>(`/token`, {
    params,
  });

  return data;
};

export const useQueryToken = (params: RequestArgs) => {
  const navigate = useNavigate();
  const processor = useCallback(() => makeQueryToken(params), []);
  const { data, isLoading: loading } = useQuery([GET_TOKEN, params], processor, {
    enabled: !!params.code,
    retry: false,
    onError: (error) => {
      navigate(routesPath.login);
    },
  });

  return { data, loading };
};
